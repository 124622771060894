<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="basic/elevator-fault-dict/page">
      <el-button
        v-if="$auth('电梯故障字典新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open()">
        {{$l("common.add", "新增")}}
      </el-button>
      <el-button
        v-if="$auth('电梯故障字典导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>
      <el-button
        v-if="$auth('电梯故障字典导出')"
        slot="btn"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('user.name','故障代码')">
            <el-input v-model.trim="filter.faultCode" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('user.type','故障名称')">
            <el-input v-model.trim="filter.faultName" type="userType"></el-input>
          </vm-search>
          <vm-search :label="$l('user.type','故障原因')">
            <el-input v-model.trim="filter.cause"></el-input>
          </vm-search>
          <vm-search :label="$l('user.type','故障集')">
            <vm-dict-select v-model.trim="filter.iotFaultSet" type="iotFaultSet"></vm-dict-select>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="iotFaultSetDesc" label="故障集" align="center"></el-table-column>
      <el-table-column prop="faultCode" label="故障代码" align="center"></el-table-column>
      <el-table-column prop="faultName" label="故障名称" align="center"></el-table-column>
      <el-table-column prop="description" label="故障描述" align="center"></el-table-column>
      <el-table-column prop="elevatorStopped" label="电梯停梯" align="center">
        <template slot-scope="scope">
          {{scope.row.elevatorStopped?'是':"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorFalling" label="电梯坠梯" align="center">
        <template slot-scope="scope">
          {{scope.row.elevatorFalling?'是':"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="peopleTrapped" label="电梯困人" align="center">
        <template slot-scope="scope">
          {{scope.row.peopleTrapped?'是':"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="level" label="故障等级" align="center"></el-table-column>
      <el-table-column prop="cause" label="故障原因" align="center" :show-overflow-tooltip="true">
        <!--        <template slot-scope="scope">-->
        <!--          <span></span>-->
        <!--        </template>-->
      </el-table-column>
      <el-table-column prop="position" label="故障位置" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="solution" label="故障解决方案" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="orderProposal" label="工单处理对策" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="130px">
        <template slot-scope="scope">
          <el-button v-if="$auth('电梯故障字典编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="$auth('电梯故障字典删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <el-dialog :visible.sync="visible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/faultDict.xlsx"
        action="basic/elevator-fault-dict/ger-import"
        name="故障字典导入"
        download-name="故障字典.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>
<script>
  import EditPage from "./FaultEdit";
  import {export2Excel} from "@/util";
  import moment from "moment";

  export default {

    components: {EditPage},
    props:{
      query:Object,
    },
    data() {
      return {
        filter: {
          phone: "",
          name: "",
          type: "",
          faultCode:"",
          status: "",
        },
        visible:false,
      };
    },
    watch:{
      query:{
        handler(params) {
          // console.log(params,139);
          for (let [key,value] of Object.entries(params)) {
            if (key==="faultCode") {

              if (value.indexOf("E0")===0) {
                this.filter.faultCode=value.replace("E0","");
              }else {
                this.filter.faultCode=value;
              }
              this.getList(1);
            }
          }
        },
        deep:true,
        // immediate:true,
      },
    },
    mounted() {
      const os = localStorage.getItem("os");
      if (os!=="new") {
        if (this.$route.query.faultCode) {
          if (this.$route.query.faultCode.indexOf("E0")===0) {
            this.filter.faultCode=this.$route.query.faultCode.replace("E0","");
          }else {
            this.filter.faultCode=this.$route.query.faultCode;
          }
        }
      }else {
        if (this.query.faultCode) {
          if (this.query.faultCode.indexOf("E0")===0) {
            this.filter.faultCode=this.query.faultCode.replace("E0","");
          }else {
            this.filter.faultCode=this.query.faultCode;
          }
        }
      }

      this.getList(1);
    },
    methods: {
      exportExcelData() {
        const title={
          iotFaultSetDesc:"故障集",
          faultCode:"故障代码",
          faultName:"故障名称",
          description:"故障描述",
          elevatorStopped:"电梯停梯",
          elevatorFalling:"电梯坠梯",
          peopleTrapped:"电梯困人",
          level:"故障等级",
          cause:"故障原因",
          position:"故障位置",
          solution:"故障解决方案",
          orderProposal:"工单处理对策",
        };
        this.$http.get("basic/elevator-fault-dict/page",{...this.filter,pageSize:3000000}).then(res=>{

          export2Excel(title,res.records,`故障字典-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible = false;
      },

      toggleStatus(row) {
        let statusDesc = row.status === "enabled" ? "禁用" : "启用";
        this.$confirm(`确定${statusDesc + row.phone}吗?`, "提示", {type: "warning"}).then(() => {
          this.$http.put(`users/${row.id}/toggleStatus`).then(() => {
            this.getList(-1);
          });
        });
      },
      resetPassword(row) {
        this.$prompt("请输入新密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          inputPattern: this.$rule.password.pattern,
          inputErrorMessage: "6~20位,不能为纯数字或者纯字母",
        }).then(({value}) => {
          this.$http.put(`users/${row.id}/resetPassword?newPassword=${value}`).then(() => {
            this.$message.success("重置密码成功");
          });
        });
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.faultCode}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("basic/elevator-fault-dict/", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.faultCode + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>
